import styles from "./Header.module.scss";

import logo from "../../assets/imgs/logo.png";
import { useState } from "react";

const Header = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => setShowNav(!showNav);

  return (
    <div className={`df aic ${styles.header}`}>
      <div className={`df aic contentWrpr`}>
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div className={`mla ${styles.mobileOpener}`} onClick={toggleNav}>
          <img src={require("../../assets/imgs/mobile-opener.png")} alt="" />
        </div>
        <div
          className={`df aic mla ffcb fs14 ${styles.navs} ${
            showNav ? styles.show : ""
          }`}
        >
          <div onClick={() => setShowNav(false)} className={`${styles.nav}`}>
            Home
          </div>
          <div onClick={() => setShowNav(false)} className={`${styles.nav}`}>
            Philosophy
          </div>
          <div onClick={() => setShowNav(false)} className={`${styles.nav}`}>
            Roadmap
          </div>
          <div onClick={() => setShowNav(false)} className={`${styles.nav}`}>
            About
          </div>
          <div onClick={() => setShowNav(false)} className={`${styles.nav}`}>
            Leadership
          </div>
          <div onClick={() => setShowNav(false)} className={`${styles.nav}`}>
            Resources
          </div>
          <a
            onClick={() => setShowNav(false)}
            className={`${styles.nav} ${styles.getStarted}`}
            href="mailto:info@witstrategies.com"
          >
            Get started
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
