import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={`df aic contentWrpr ${styles.contentWrpr}`}>
        <div className={`df aic ffcb fs14 ${styles.linksWrpr}`}>
          <div className={`df aic ${styles.link} ${styles.siteMap}`}>
            Site map
          </div>
          <div className={`df aic ${styles.link}`}>Home</div>
          <div className={`df aic ${styles.link}`}>Philosophy</div>
          <div className={`df aic ${styles.link}`}>Roadmap</div>
          <div className={`df aic ${styles.link}`}>About</div>
          <div className={`df aic ${styles.link}`}>Leadership</div>
          <div className={`df aic ${styles.link}`}>Resources</div>
        </div>
        <div className={`mla ${styles.logoWrpr}`}>
          <img src={require("../../assets/imgs/logo-footer.png")} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
