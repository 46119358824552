import styles from "./Values.module.scss";

const Values = (props) => {
  return (
    <div className={styles.values}>
      <div className={`df aic contentWrpr ${styles.contentWrpr}`}>
        <h2 className={`ffcblack fs40`}>Our Core Values</h2>
        <div className={styles.statement}>
          <h3 className={`ffcblack fs40`}>The WIT way</h3>
          <p className={`ffcm fs20`}>
            We do a rigorous process, searching through many insurance carriers
            and financial institutions to bring the best of the best to our
            clients. By doing so we can give our clients customized policies
            rather than fitting them into a generic mold.
          </p>
        </div>

        <div className={`df mla ${styles.sectionsWrpr}`}>
          <div className={`df ffc aic ${styles.sec}`}>
            <h5 className={`ffcm fs30`}>Simplicity</h5>
            <p className={`ffcm fs20 tac ${styles.text}`}>
              We make insurance products simpler and easier to understand
            </p>
          </div>

          <div className={`df ffc aic ${styles.sec}`}>
            <h5 className={`ffcm fs30`}>Quality</h5>
            <p className={`ffcm fs20 tac ${styles.text}`}>
              We go to great lengths to satisfy our customers. We don’t cut
              corners or take chances.
            </p>
          </div>

          <div className={`df ffc aic ${styles.sec}`}>
            <h5 className={`ffcm fs30`}>Transparency</h5>
            <p className={`ffcm fs20 tac ${styles.text}`}>
              We’re transparent and honest. You’ll be aware of possible
              fluctuations up front.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
