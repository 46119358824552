import styles from "./Landing.module.scss";

const Landing = (props) => {
  return (
    <div className={`${styles.landing}`}>
      {/* <img src={bg} alt="bg" /> */}
      <div className={`df ffc jcc contentWrpr`}>
        <h2 className={`ffcblack fs55`}>
          We help high net-worth individuals preserve their wealth for
          generations. Literally.
        </h2>
        <div className={`df aic ${styles.btnsWrpr}`}>
          <a href="mailto:info@witstrategies.com" className={`tac ffcb fs14`}>
            Contact Us
          </a>
          <button className={`tac ffcb fs14 ${styles.lmBtn}`}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
