import Landing from "./components/landing/Landing";
import Values from "./components/values/Values";

const Home = (props) => {
  return (
    <>
      <Landing />
      <Values />
    </>
  );
};

export default Home;
